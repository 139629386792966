/***
 * WGS-84：是国际标准，GPS坐标（Google Earth使用、或者GPS模块）
 * GCJ-02：中国坐标偏移标准，Google Map、高德、腾讯使用
 * BD-09：百度坐标偏移标准，Baidu Map使用
 * Example
 * 1.WGS-84 to GCJ-02:GPS.gcj_encrypt();
 * 2.GCJ-02 to WGS-84 粗略:GPS.gcj_decrypt();
 * 3.GCJ-02 to WGS-84 精确(二分极限法):GSP.gcj_decrypt_exact();// var threshold = 0.000000001; 目前设置的是精确到小数点后9位，这个值越小，越精确，但是javascript中，浮点运算本身就不太精确，九位在GPS里也偏差不大了
 * 4.GCJ-02 to BD-09:GPS.bd_encrypt();
 * 5.BD-09 to GCJ-02:GPS.bd_decrypt();
 * 6.求距离:GPS.distance();
 */
export const GPS = {
    PI : 3.14159265358979324,
    x_pi : 3.14159265358979324 * 3000.0 / 180.0,
    delta : function (lat, lon) {
        var a = 6378245.0; //  a: 卫星椭球坐标投影到平面地图坐标系的投影因子。
        var ee = 0.00669342162296594323; //  ee: 椭球的偏心率。
        var dLat = this.transformLat(lon - 105.0, lat - 35.0);
        var dLon = this.transformLon(lon - 105.0, lat - 35.0);
        var radLat = lat / 180.0 * this.PI;
        var magic = Math.sin(radLat);
        magic = 1 - ee * magic * magic;
        var sqrtMagic = Math.sqrt(magic);
        dLat = (dLat * 180.0) / ((a * (1 - ee)) / (magic * sqrtMagic) * this.PI);
        dLon = (dLon * 180.0) / (a / sqrtMagic * Math.cos(radLat) * this.PI);
        return {'lat': dLat, 'lng': dLon};
    },

    //WGS-84 to GCJ-02
    gcj_encrypt : function (wgsLat, wgsLon) {
        if (this.outOfChina(wgsLat, wgsLon)){
        	return {'lat': wgsLat, 'lng': wgsLon};
        }
        var d = this.delta(wgsLat, wgsLon);
        return {'lat' : wgsLat + d.lat,'lng' : wgsLon + d.lng};
    },
    //GCJ-02 to WGS-84
    gcj_decrypt : function (gcjLat, gcjLon) {
        if (GPS.outOfChina(gcjLat, gcjLon)){
        	return {'lat': gcjLat, 'lng': gcjLon};
        }
        var d = GPS.delta(gcjLat, gcjLon);
        return {'lat': gcjLat - d.lat, 'lng': gcjLon - d.lng};
    },
    //GCJ-02 to WGS-84 exactly
    gcj_decrypt_exact : function (gcjLat, gcjLon) {
        var initDelta = 0.01;
        var threshold = 0.000000001;
        var dLat = initDelta, dLon = initDelta;
        var mLat = gcjLat - dLat, mLon = gcjLon - dLon;
        var pLat = gcjLat + dLat, pLon = gcjLon + dLon;
        var wgsLat, wgsLon, i = 0;
        while (1) {
            wgsLat = (mLat + pLat) / 2;
            wgsLon = (mLon + pLon) / 2;
            var tmp = GPS.gcj_encrypt(wgsLat, wgsLon)
            dLat = tmp.lat - gcjLat;
            dLon = tmp.lon - gcjLon;
            if ((Math.abs(dLat) < threshold) && (Math.abs(dLon) < threshold))
                break;
 
            if (dLat > 0) pLat = wgsLat; else mLat = wgsLat;
            if (dLon > 0) pLon = wgsLon; else mLon = wgsLon;
 
            if (++i > 10000) break;
        }
        //console.log(i);
        return {'lat': wgsLat, 'lng': wgsLon};
    },
    //GCJ-02 to BD-09
    bd_encrypt : function (gcjLat, gcjLon) {
        if (GPS.outOfChina(gcjLat, gcjLon)){
        	return {'lat': gcjLat, 'lng': gcjLon};
        }
        var x = gcjLon, y = gcjLat;  
        var z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * this.x_pi);  
        var theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * this.x_pi);  
        bdLon = z * Math.cos(theta) + 0.0065;  
        bdLat = z * Math.sin(theta) + 0.006; 
        return {'lat' : bdLat,'lng' : bdLon};
    },
    //BD-09 to GCJ-02
    bd_decrypt : function (bdLat, bdLon) {
        var x = bdLon - 0.0065, y = bdLat - 0.006;
        var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * this.x_pi);
        var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * this.x_pi);
        var gcjLon = z * Math.cos(theta);
        var gcjLat = z * Math.sin(theta);
        return {'lat' : gcjLat, 'lng' : gcjLon};
    },
    //BD-09 to WGS-84
    bd_to_wgs : function (bdLat, bdLon) {
    	var gcjLatLng = GPS.bd_decrypt(bdLat,bdLon);
    	var wgsPoint = GPS.gcj_decrypt(gcjLatLng.lat, gcjLatLng.lng);
        return {'lat' : wgsPoint.lat, 'lng' : wgsPoint.lng};
    },
    //WGS-84 to Web mercator
    //mercatorLat -> y mercatorLon -> x
    mercator_encrypt : function(wgsLat, wgsLon) {
        var x = wgsLon * 20037508.34 / 180.;
        var y = Math.log(Math.tan((90. + wgsLat) * this.PI / 360.)) / (this.PI / 180.);
        y = y * 20037508.34 / 180.;
        return {'lat' : y, 'lng' : x};
    },
    // Web mercator to WGS-84
    // mercatorLat -> y mercatorLon -> x
    mercator_decrypt : function(mercatorLat, mercatorLon) {
        var x = mercatorLon / 20037508.34 * 180.;
        var y = mercatorLat / 20037508.34 * 180.;
        y = 180 / this.PI * (2 * Math.atan(Math.exp(y * this.PI / 180.)) - this.PI / 2);
        return {'lat' : y, 'lng' : x};
    },
    // two point's distance
    distance : function (latA, lonA, latB, lonB) {
        var earthR = 6371000.;
        var x = Math.cos(latA * this.PI / 180.) * Math.cos(latB * this.PI / 180.) * Math.cos((lonA - lonB) * this.PI / 180);
        var y = Math.sin(latA * this.PI / 180.) * Math.sin(latB * this.PI / 180.);
        var s = x + y;
        if (s > 1) s = 1;
        if (s < -1) s = -1;
        var alpha = Math.acos(s);
        var distance = alpha * earthR;
        return distance;
    },
    // two point's include altitude distance
    distanceAccountAltitude : function (latA, lonA, altA, latB, lonB, altB)
    {
        var EARTH_RADIUS = 6378137.; // 地球近似半径
        var radLat1 = latA * this.PI / 180.0;
        var radLat2 = latB * this.PI / 180.0;
        var a = radLat1 - radLat2;
        var b = (lonA * this.PI / 180.0) - (lonB * this.PI / 180.0);
        
        // Math.asin() - 反正弦函数
        // Math.sqrt() - 平方根
        // Math.pow(x,y) - 可返回 x 的 y 次幂的值。
        var dst = 2 * Math.asin((Math.sqrt(Math.pow(Math.sin(a / 2), 2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2) )));
        dst = dst * EARTH_RADIUS;
        
        // count altitude
        var altAbs = 0 < altA - altB ? altA - altB : altB - altA; // 取绝对值
        var dis = Math.sqrt(Math.pow(dst, 2) + Math.pow(altAbs, 2));
        
        return dis;
    },
    outOfChina : function (lat, lon) {
        if (lon < 72.004 || lon > 137.8347)
            return true;
        if (lat < 0.8293 || lat > 55.8271)
            return true;
        return false;
    },
    transformLat : function (x, y) {
        var ret = -100.0 + 2.0 * x + 3.0 * y + 0.2 * y * y + 0.1 * x * y + 0.2 * Math.sqrt(Math.abs(x));
        ret += (20.0 * Math.sin(6.0 * x * this.PI) + 20.0 * Math.sin(2.0 * x * this.PI)) * 2.0 / 3.0;
        ret += (20.0 * Math.sin(y * this.PI) + 40.0 * Math.sin(y / 3.0 * this.PI)) * 2.0 / 3.0;
        ret += (160.0 * Math.sin(y / 12.0 * this.PI) + 320 * Math.sin(y * this.PI / 30.0)) * 2.0 / 3.0;
        return ret;
    },
    transformLon : function (x, y) {
        var ret = 300.0 + x + 2.0 * y + 0.1 * x * x + 0.1 * x * y + 0.1 * Math.sqrt(Math.abs(x));
        ret += (20.0 * Math.sin(6.0 * x * this.PI) + 20.0 * Math.sin(2.0 * x * this.PI)) * 2.0 / 3.0;
        ret += (20.0 * Math.sin(x * this.PI) + 40.0 * Math.sin(x / 3.0 * this.PI)) * 2.0 / 3.0;
        ret += (150.0 * Math.sin(x / 12.0 * this.PI) + 300.0 * Math.sin(x / 30.0 * this.PI)) * 2.0 / 3.0;
        return ret;
    },
    cn_border_sw : {lng:73.55770111,lat:18.25359165}, //大陆范围-西南
    cn_border_ne : {lng:134.7739258,lat:53.56085968}, //大陆范围-东北
    hk_border : [[113.825922,114.445501,22.153153,22.442493],[113.947258,114.439346,22.441715,22.492956],[114.100019,114.395993,22.492523,22.532833]], //香港范围
    points : [[73.55770111,75.65194317,36.52372911,40.56062125]
    , [75.64510653,78.39082382,34.53553468,41.62186618]
    , [78.37579437,81.01534526,30.15995738,45.19814562]
    , [80.99049166,84.48687391,28.7023929,47.25119493]
    , [84.48687391,87.81811931,27.69463205,49.30424425]
    , [87.81811931,91.5657704,27.00866625,49.29919261]
    , [91.5657704,95.0016325,27.5224139,45.19814562]
    , [94.97387283,97.27816414,28.14804254,44.38744713]
    , [97.27709965,99.00052155,23.55256431,42.82185507]    
    , [99.00158604,102.8087237,21.02514567,42.81395933]
    , [102.8087237,106.2502377,22.4821484,42.21791275]
    , [106.2180244,108.5588869,21.25959899,42.61527713]
    , [108.5871392,111.3815241,18.25359165,43.57514294]
    , [111.3152967,114.9439748,21.15760046,45.7279648]  
    , [114.9439748,118.6221828,22.6146032,49.8970233]
    , [118.5740006,123.2393038,21.62119224,53.56085968]
    , [123.1730764,126.1773026,38.64163331,53.56085968]
    , [126.1307527,129.9345791,40.95984402,53.56085968]
    , [129.9345791,132.444912,42.21791275,49.17178945]    
    , [132.444912,134.7739258,44.53616233,48.37706069]],
    isPointInPolygon : function(lng, lat){
    	// 粗粒度大陆范围
    	if(!(lng >= this.cn_border_sw.lng 
    			&& lng <= this.cn_border_ne.lng 
    			&& lat >= this.cn_border_sw.lat 
    			&& lat <= this.cn_border_ne.lat))
    		return false;
    	
    	var hk_b = this.hk_border;
    	for(var i = 0; i < hk_b.length; i++)
    	{
    		if(lng >= hk_b[i][0]
        			&& lng <= hk_b[i][1] 
        			&& lat >= hk_b[i][2] 
        			&& lat <= hk_b[i][3])
    			return false;
    	}
    	
    	var pts = this.points;
    	var index = this.binarySearch(pts, 0, pts.length - 1, lng);
    	if(-1 == index)
    		return false;
    	
    	// 比较纬度
		if(lat >= pts[index][2] && lat <= pts[index][3])
			return true;
		else
			return false;
    },
    binarySearch : function(arr, left, right, value)
    {
    	// 循环方式
    	while(left <= right)
    	{
    		// 防止极端情况下的整形溢出，使用下面的逻辑求出mid
    		var mid = Math.floor(left + (right - left) / 2);
    		
    		if(value < arr[mid][0])
        	{
    			right = mid - 1;
        	}
        	else if(value > arr[mid][1])
        	{
        		left = mid + 1;
        	}
        	else
        	{
        		return mid;
        	}
    	}
    	
    	return -1;
    	
//    	// 递归方式
//    	if(left > right)
//    		return -1;
//    	
//    	// 防止极端情况下的整形溢出，使用下面的逻辑求出mid
//		var mid = Math.floor(left + (right - left) / 2);
//    	
//    	if(value < arr[mid][0])
//    	{
//    		return this.binarySearch(left, mid - 1, value);
//    	}
//    	else if(value > arr[mid][1])
//    	{
//    		return this.binarySearch(mid + 1, right, value);
//    	}
//    	else
//    	{
//    		return mid;
//    	}
    }
};
