<template>
	<div id="map"></div>
  <button @click="toImage()">导出图片</button>
</template>

<script>
import { downloadImg } from '../../utils/utils';
import { positions } from './points.js'
import { makePolylineLayer } from './map.js'
import { GPS } from './gps.js';
export default {
	name: "map",
	created() {
    this.setupScripts().then(this.initMapComponent);
	},
  deactivated() {
    console.log('222');
  },
  data() {
    return {
      map: null
    }
  },
  methods: {
    toImage() {
      const dom = document.getElementById('map');
      domtoimage.toPng(dom).then(downloadImg).catch(ex => { });
    },
    initMapComponent() {
      const map = new TMap.Map('map', {
        rotation: 20, // 设置地图旋转角度
        pitch: 30, // 设置俯仰角度（0~45）
        zoom: 17, // 设置地图缩放级别
        baseMap: [
          { type: 'vector' }, // 设置矢量底图
        ],
        showControl: false,
        renderOptions: {
          // renderOptions文档地址：https://lbs.qq.com/webApi/javascriptGL/glDoc/docIndexMap#10
          preserveDrawingBuffer: true, // 保留地图的渲染缓冲 默认false
        },
      });
      this.map = map;

      const gcj_points = positions.map(p => GPS.gcj_encrypt(p.lat, p.lng));
      console.log('gcj', gcj_points);

      const paths = gcj_points.map(p => (
        new TMap.LatLng(p.lat, p.lng)
      ))
      const polylineLayer = makePolylineLayer(map, paths, TMap);
      const latlngbounds = new TMap.LatLngBounds(); 
      for (const path of paths) {
        latlngbounds.extend(path);
      }
      map.fitBounds(latlngbounds, {padding: 30});
    },
    /**
    * 加载腾讯地图脚本
    */
    setupScripts() {
      const urlList = [
        'https://mapapi.qq.com/web/jsapi/jsapi-gl/js/dom-to-image.min.js',
        'https://map.qq.com/api/gljs?v=1.exp&key=OB4BZ-D4W3U-B7VVO-4PJWW-6TKDJ-WPB77'
      ];
      const loadScripts = urlList.map(url => {
        const script = document.createElement('script');
        script.src = url;
        return script;
      }).map(script => {
        return new Promise((resolve, reject) => {
          document.body.appendChild(script);
          script.onload = () => {
            resolve(script);
          };
          script.onerror = (e) => {
            reject(e)
          }
        })
      });
      return Promise.all(loadScripts);
    }
  }
};
</script>

<style lang="scss" scoped>
#map {
  width: 1000px;
  height: 1000px;
}
</style>
