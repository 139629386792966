function pairs(arr) {
  return _pairs(arr, []);
}

function _pairs(arr, res) {
  if (arr.length >= 2 ) {
    const [first, second, ...rest] = arr;
    res.push([first, second]);
    return _pairs([second, ...rest], res);
  }
  return res;
}
export function makePolylineLayer(map, paths, TMap) {
  const list = pairs(paths)
  const polylineLayer = new TMap.MultiPolyline({
    map: map, // 绘制到目标地图
    // 折线样式定义
    styles: {
      'style_blue': new TMap.PolylineStyle({
        color: '#3777FF', //线填充色
        width: 10, //折线宽度
        borderWidth: 0, //边线宽度
        showArrow: true,
        arrowOptions: {
          space: 70
        },
        lineCap: 'round',
      }),
    },
    geometries: [{
      styleId: 'style_blue',
      rainbowPaths: list.map(item => ({
        path: [item[0], item[1]],
        color: 'rgba(0, 180, 0, 1)'
      }))
    }]
  });
}
